import React from 'react'
import Layout from '../components/layout'
import './privacy-policy.module.css'

const AppPrivacyPolicyPage = (props) => (
  <Layout {...props}>
    <section styleName='privacy-policy-page' className='sm-gutters'>
      <div className='container'>
        <h2>Data Protection Declaration for Mobius Vision demo app</h2>
        <p>
        The App is provided by Mobius Labs GmbH (hereinafter referred to as "Provider"). Responsible body according to § 3 Section 7 BDSG and service provider according to § 13 TMG. Further information on the Provider is given in the imprint.
        </p>
        <p>
          Version 0.1.2, September 10, 2018
        </p>
        <p>
          We adhere to the requirements of the Federal Data Protection Act (BDSG), the Telemedia Act (TMG), and all relevant European regulations.
          In this Data Protection Declaration our app users will be referred to collectively as "User".
          In the following the Provider informs the User of the nature, scope and purpose of collecting, processing and use of personal data. Users can access the present Data Protection Statement at any time inside the App and this detailed version of the Data Protection Statement at the company website at LINK.
        </p>
        <p>
          Data protection is important to us. You can therefore be confident that we will handle your data sensitively and according to the regulations.
        </p>
        <h3>
          1. How this App works
        </h3>
        <p>
        This demo App includes a copy of the Mobius Vision SDK and therefore runs locally on the device.
        The App has no connection to any server or remote database. All data of the User stays on the device. Main purpose of the App is not to analyse personal data, this is rather an expectable side occurrence when analysing folders of images which may or may not contain personal data.
        </p>
        <h3>
          2. Collection, processing and use of personal data
        </h3>
        <p>
        We will collect, process, and use the personal data provided with your consent only for the purposes disclosed to you.
        </p>
        <h3>
        3. Details on provided, processed and generated data by the App
        </h3>
        <p>
        Provided Data :
        </p>
        <p>
        The Provider makes the following data available within the App:
        </p>
        <ul>
          <li>
            a folder with example images to be displayed as normal album
          </li>
          <li>
            a list of example keywords based on the example images
          </li>
          <li>
            a folder with images for search that appear only in the search module of the app
          </li>
          <li>
            texts displayed in the App
          </li>
        </ul>
        <p>
        Processed data on the device :
        </p>
        <p>
        The following information is read from the Users device so that the Provider is able to give support to the User
        </p>
        <ul>
          <li>
            Images are processed locally by the Mobius Vision SDK which is essential for the service provided. The App User can choose which folder to analyse from the device or remote data storages. All images in a chosen folder are analysed. These images may contain personal information such as faces, number plates or as well as personal meta-data. However, the SDK is not capable to indentify a specific natural person. Meta-data of these images are not analysed. Images are not altered. Images are also not copied in other locations on the device.
          </li>
        </ul>
        <p>
        Generated data on the device :
        </p>
        <p>
        The following information may be generated during the app usage
        </p>
        <ul>
          <li>
          Intermediate processing results (features) are stored in the cache for further processing for all images from chosen folders. This is essential for some functions of the App such as Search and Training of a new Filter.
          </li>
          <li>
          Information needed to display normal and smart albums such as the thumbnail image, number of images in the folder, predicted keywords and scores.
          </li>
          <li>
          Trained filters (custom models in the SDK) are stored after training.
          </li>
        </ul>
        <p>
        The Provider has no access to this data outside the App. There is no processing or generation of data other than the cases specified above.
        </p>
        <h3>
        6. Data secrecy
        </h3>
        <p>
          We have appropriate technical and organizational measures in place in order to protect any personal data that is collected, processed and used by us and that shall prevent loss, unauthorized collection, processing and use of such data.
        </p>
        <h3>
        7. Data subjects’ rights (according to EU-GDPR)
        </h3>
        <p>
          The data subject has the following rights:
          <br />Right to information
          <br />Right to withdraw given consent at any time (Article 7(3) GDPR)
          <br />Right of access (Art.15 GDPR)
          <br />Right to rectification (Art. 16 GDPR)
          <br />Right to restriction of processing (Art. 18 GDPR)
          <br />Right to data portability (Art.20 GDPR)
          <br />Right to object (Art. 21 GDPR)
        </p>
        <h3>
        8. Right to erasure (Art. 17 EU-GDPR)
        </h3>
        <p>
        The provider does not hold any data from the App User outside the app. The User may delete the intermediate results (features) which may or may not been generated from data containing personal information. This data is stored on the phone locally and can be deleted by the User any time. The App User may choose to solely use the provided example folder to evade processing of personal data.
        </p>
        <h3>
        9. Right to file complaints with regulatory authorities (Article 77 GDPR)
        </h3>
        <p>
          If there has been a breach of data protection legislation, the person
          affected may file a complaint with the competent regulatory
          authorities.  The competent regulatory authority for matters related
          to data protection legislation is the data protection officer of the
          German state in which our company is headquartered. A list of data
          protection officers and their contact details can be found at the
          following link: <a
            href='https://www.bfdi.bund.de/DE/Infothek/Anschriften_Links/anschriften_links-node.html'>Contact
            information to German authorities</a>
        </p>
        <h3>
        10. Amendment of this data protection declaration
        </h3>
        <p>
          We reserve the right to update this data protection declaration at
          regular intervals in accordance with the development of the underlying
          procedures/processes. You may always access and print the latest data
          protection information on the website at <a
            href='/privacy-policy-app'>Mobius Privacy Policy Demo App</a>
        </p>
        <h3>
          Data Protection Contact
        </h3>
        <p>
          In case you have any questions about data protection law or your
          rights as the data subject, you may directly contact our Data
          Protection Officer. See our <a href="/imprint">imprint</a> for contact information.
        </p>
      </div>
    </section>
  </Layout>
)

export default AppPrivacyPolicyPage
